import React from "react"
import {Container, styled, Typography} from "@mui/material";
import {graphql} from "gatsby";
import { Helmet} from "gatsby-plugin-react-i18next";
import SiteLayout from "components/layouts/SiteLayout";
import SectionTitle from "components/atoms/SectionTitle";

const Text = styled(Typography)`
  margin-bottom: 1rem;
  ul, ol {
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 40px;
  }
`;

export default function LaboratoryUnitsPage() {
  return (
    <SiteLayout>
      <Helmet>
        <title>DokDok - Pediatryczne punkty pobrań Synevo</title>
      </Helmet>
      <Container>
        <SectionTitle>
          Pediatryczne punkty pobrań Synevo
        </SectionTitle>
        <Text>
          W przypadku <strong>dzieci poniżej 1. roku życia</strong>, w celu wykonania badań należy udać się do punktów
          pobrań <strong>Synevo</strong> specjalizujących się w pobraniach u małych dzieci, określanych jako <strong>pediatryczne
          punkty pobrań</strong>. Z uwagi na specjalne wymagania dotyczące najmłodszych, w przychodniach DokDok nie
          wykonujemy pobrań u dzieci poniżej 1 roku życia.
          <br/>
          <br/>
          Zgłaszając się do punktu pobrań Synevo podaj przed pobraniem swój <strong>numer skierowania</strong>, który
          przesłaliśmy do Ciebie mailem. <strong>Skierowanie jest ważne bezterminowo.</strong>
          <br/>
          <br/>
          <strong>
            Badania odpłatne nie są uwzględnione na skierowaniu, więc musisz o nich dodatkowo poinformować pracownika
            punktu pobrań. Zalecone przez lekarza badania odpłatne sprawdzisz w aplikacji DokDok w historii wizyty
            dziecka.
          </strong>
        </Text>
        <Typography variant="h2">Wrocław</Typography>
        <Text>
          <ol>
            <li>
              <a rel="noreferrer" href="https://g.page/LaboratoriumWroclawGlowny">
                Punkt pobrań Synevo ul. Swobodna 8A
              </a>
              <br/>
              poniedziałek - piątek 7:00-14:00
            </li>
            <li>
              <a rel="noreferrer" href="https://g.page/LaboratoriumWroclawFieldorfa">
                Punkt pobrań Synevo ul. Fieldorfa 2
              </a>
              <br/>
              poniedziałek - piątek 7:00-14:00
            </li>
          </ol>
        </Text>
        <Typography variant="h2">Warszawa</Typography>
        <Text sx={{ pb: { xs: 2, md: 4, lg: 6 }}}>
          <ol>
            <li>
              <a rel="noreferrer" href="https://g.page/LaboratoriumWarszawaDzika">
                Punkt pobrań Synevo ul. Dzika 4
              </a>
              <br/>
              poniedziałek - piątek 10:00-14:00 (dzieci poniżej 6 m.ż. konieczna uprzednia rejestracja telefoniczna)
              <br/>
              soboty - tylko rejestracja telefoniczna
            </li>
          </ol>
        </Text>
      </Container>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

